import { APP_INITIALIZER } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, take, takeWhile, tap } from 'rxjs/operators';
import { featureFlagsActions } from '../+state/actions/feature-flags.actions';
import { FeatureFlagFacadeService } from '../services/feature-flag-facade/feature-flag-facade.service';

export const featureFlagInitializerFactory = (featureFlagFacadeService: FeatureFlagFacadeService) => {
  return () => {
    featureFlagFacadeService.dispatch(featureFlagsActions.initialize({ context: { anonymous: true } }));

    return new Observable<boolean>((observer) => {
      // Wait until the launch darkly is initialized
      featureFlagFacadeService
        .initialized()
        .pipe(
          filter((isInitialised) => isInitialised),
          take(1)
        )
        .subscribe(() => {
          observer.next(true);
          observer.complete();
        });
    }).toPromise();
  };
};

export const FEATURE_FLAG_INITIALIZER = {
  provide: APP_INITIALIZER,
  useFactory: featureFlagInitializerFactory,
  multi: true,
  deps: [FeatureFlagFacadeService],
};
