<div class="search-wrapper">
  <kody-search [searchControl]="searchControl" kp-object="menu_search_input"></kody-search>
</div>
<ng-container *ngIf="categories">
  <kody-menu-categories
    *ngIf="searchTerm === ''"
    class="store-menu__categories"
    [ngClass]="{ 'store-menu__categories--merchant': isMerchantView }"
    [isMerchantView]="isMerchantView"
    [categories]="categories"
    [selectedCategory]="selectedCategory"
    (longPressCategory)="longPressCategory.emit($event)"
    (selectCategory)="onSelectCategory($event)"
  ></kody-menu-categories>
</ng-container>

<!-- Merchant Items -->
<ng-container *ngIf="isMerchantView">
  <kody-menu-item
    *ngFor="let item of filteredItems; trackBy: itemTrackByFn; let $i = index"
    [item]="item"
    [baseApiUrlCms]="baseApiUrlCms"
    (click)="selectItem.emit(item)"
    (kodyLongPress)="longPressItem.emit(item)"
    [attr.kp-object]="'menu_item_' + $i"
  >
    <ng-container merchant-menu-enable-disable>
      <kody-chip color="success" *ngIf="item.statusId === storeItemStatusId.enabled"> Enabled </kody-chip>
      <kody-chip color="danger" *ngIf="item.statusId === storeItemStatusId.disabled"> Disabled </kody-chip>
    </ng-container>
  </kody-menu-item>
</ng-container>

<!-- Consumer Items -->
<ng-container *ngIf="!isMerchantView">
  <kody-menu-item
    kodyRippleEffect
    *ngFor="let item of filteredItems; trackBy: itemTrackByFn; let $i = index"
    [item]="item"
    [baseApiUrlCms]="baseApiUrlCms"
    (showInformation)="showInformation.emit($event)"
    (click)="onAddItem(item)"
    [attr.kp-object]="'menu_item_' + $i"
  >
    <ng-container consumer-menu-quantity *ngIf="cartItemQuantityMap$ | async as quantityMap">
      <kody-chip color="primary" *ngIf="quantityMap[item.merchantItemId]">x{{ quantityMap[item.merchantItemId] }}</kody-chip>
    </ng-container>
  </kody-menu-item>
</ng-container>
