<div class="multi-stores-container">
  <ion-list lines="none" class="multi-stores-list">
    <ng-container *ngFor="let restaurant of restaurants">
      <ion-item (click)="restaurantSelected.emit(restaurant.merchantStoreId)" class="multi-stores-item">
        <ion-thumbnail class="multi-stores-item-image" *ngIf="restaurant.imageUrl">
          <ion-img [src]="baseImageUrl + restaurant.imageUrl"></ion-img>
        </ion-thumbnail>
      </ion-item>
    </ng-container>
  </ion-list>
</div>
