import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TrackByFunction,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { APP_ENVIRONMENT, SearchService } from '@libs/shared/utilities';
import { MerchantStore, StoreCategory, StoreItem, StoreItemStatusId, Cart, Environment } from '@libs/shared/types';
import { ConsumerMenuFacadeService } from '../../+state/services/consumer-menu-facade/consumer-menu-facade.service';
import { consumerMenuPageActions } from '../../+state/actions/consumer-menu-page.actions';
import { AppStateFacadeService } from '@libs/app-kody-order/utility-app-state';
import { skip } from 'rxjs/operators';

@Component({
  selector: 'kody-store-menu',
  templateUrl: './store-menu.container.html',
  styleUrls: ['./store-menu.container.scss'],
})
export class StoreMenuContainerComponent implements OnChanges, OnInit, OnDestroy {
  @Input() categories: StoreCategory[];
  @Input() items: StoreItem[];
  @Input() store: MerchantStore;
  @Input() isMerchantView?: boolean; // Merchant Menu Only
  @Input() cart: Cart; // Consumer Menu Only

  @Output() showInformation = new EventEmitter<StoreItem>();
  @Output() selectItem = new EventEmitter<StoreItem>(); // Merchant Menu Only
  @Output() longPressCategory = new EventEmitter<StoreCategory>(); // Merchant Menu Only
  @Output() longPressItem = new EventEmitter<StoreItem>(); // Merchant Menu Only
  @Output() addItem = new EventEmitter<StoreItem>(); // Consumer Menu Only

  readonly baseApiUrlCms = `${this.environment.baseApiUrl}/cms/`;
  readonly storeItemStatusId = StoreItemStatusId;
  filteredItems: StoreItem[] = [];
  sortedItems: StoreItem[] = [];
  selectedCategory: StoreCategory = null;
  searchTerm = '';
  searchControl = new FormControl();
  destroy$ = new Subject<boolean>();
  search$: Observable<string>;
  clicked = {};
  quantity = 0;

  cartItemQuantityMap$: Observable<{ [key: string]: number }>;
  itemTrackByFn: TrackByFunction<StoreItem> = (index, item) => item.itemId;

  constructor(
    private searchService: SearchService,
    private consumerMenuFacadeService: ConsumerMenuFacadeService,
    private appStateFacadeService: AppStateFacadeService,
    @Inject(APP_ENVIRONMENT) private environment: Environment
  ) {}

  ngOnInit(): void {
    this.selectedCategory = this.categories?.[0];
    this.searchService
      .search$(this.searchControl, this.destroy$)
      .pipe(skip(1)) // Prevents additional request for the menu items
      .subscribe((searchTerm) => {
        this.searchTerm = searchTerm;

        // Merchant menu is still using GraphQl so the search is done on the client
        if (this.isMerchantView) {
          if (this.searchTerm === '') {
            this.filteredItems = this.filterMerchantMenuItems(this.sortedItems);
          } else {
            this.filterItemsBySearchTerm(this.sortedItems, searchTerm);
          }
        } else {
          this.consumerMenuFacadeService.dispatch(
            consumerMenuPageActions.searchMenuItems({ searchTerm, menuCategoryId: this.selectedCategory.restaurantMenuCategoryId })
          );
        }
      });
    this.cartItemQuantityMap$ = this.appStateFacadeService.getCurrentItemQuantity();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges({ items }: SimpleChanges): void {
    if (items && items.currentValue) {
      this.sortedItems = [...items.currentValue].sort((a, b) => a.menuCategoryOrderIndex - b.menuCategoryOrderIndex);
      if (this.searchTerm !== '') {
        this.filterItemsBySearchTerm(this.sortedItems, this.searchTerm);
      } else {
        this.selectedCategory = this.selectedCategory ?? this.categories[0];
        this.filteredItems = !this.isMerchantView ? this.sortedItems : this.filterMerchantMenuItems(this.sortedItems);
      }
    }
  }

  filterMerchantMenuItems(items: StoreItem[]): StoreItem[] {
    return items.filter((item) => item.menuCategoryId === this.selectedCategory?.restaurantMenuCategoryId);
  }

  onSelectCategory(category: StoreCategory): void {
    this.selectedCategory = category;

    if (this.isMerchantView) {
      this.filteredItems = this.sortedItems.filter((item) => item.menuCategoryId === category.restaurantMenuCategoryId);
      return;
    }
    this.consumerMenuFacadeService.dispatch(
      consumerMenuPageActions.filterByMenuCategory({ menuCategoryId: category.restaurantMenuCategoryId })
    );
  }

  filterItemsBySearchTerm(sortedItems: StoreItem[], searchTerm: string): void {
    this.filteredItems = this.searchService.getFilteredList(sortedItems, searchTerm, ['name', 'description']);
  }

  private disableItem(id: string): void {
    this.clicked[id] = true;
    setTimeout(() => {
      delete this.clicked[id];
    }, 300);
  }

  onAddItem(item: StoreItem): void {
    if (!this.clicked[item.itemId]) {
      this.addItem.emit(item);
      this.disableItem(item.itemId);
    }
  }
}
