import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const traceId = `kodyorder-${uuidv4()}`;

    // Clone the request to add the new header
    const clonedRequest = req.clone({
      headers: req.headers.set('kp-trace-id', traceId),
    });

    return next.handle(clonedRequest);
  }
}
