import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { kodyOrderBaseRoutes } from '@libs/shared/types';

@Injectable({
  providedIn: 'root',
})
export class KodyOrderRouterUtilityService {
  constructor(private router: Router) {}

  isPaymentRoute(): boolean {
    return this.router.url.split('/')[this.router.url.split('/').length - 1] === kodyOrderBaseRoutes.payment;
  }
}
