import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
@Injectable({
  providedIn: 'root',
})
export class TimeService {
  constructor() {
    dayjs.extend(isToday);
  }
  /**
   * Calculates remaining time between to and from dates, and returns a formatted message:
   * less than an hour left: 'in XX minutes
   * more than an hour left, on the same day: 'at XX:XX  today'
   * more than an hour left, on an upcoming day: 'at XX:XX on DAY'
   *
   * @param toTime UTC string
   * @param fromTime UTC string (will default to current time if falsy)
   */
  getRemainingTimeMessage(toTime: string, fromTime?: string): string {
    const to = dayjs(toTime);
    const from = dayjs(fromTime);
    if (toTime && to.isValid() && from.isValid() && from.isBefore(to)) {
      return to.diff(from, 'h') < 1
        ? `in ${to.diff(from, 'm')} minutes`
        : `at ${to.format('HH:mm')} ${to.isToday() ? 'today' : 'on ' + to.format('dddd')}`;
    }
    return '';
  }

  /**
   * Formats the numeric value in a user friendly format,
   * handling empty values and optionally pluralising the lable
   *
   * @param value
   * @param label
   * @param pluralise flag to indicate whether the label should be pluralised
   */
  humaniseValue(value: number = 0, label: string, pluralise = false): string {
    const suffix = `${value > 1 && pluralise ? 's' : ''}`;
    return `${value || ''}${value ? `${label}${suffix}` : ''}`;
  }

  /**
   * Calculates and formats duration time in hours and minutes
   * in a user friendly format
   *
   * @param durationMinutes number of minutes
   * @param condensed flag to shortens labels and drop pluralisation
   */
  getDurationMessageFromMinutes(durationMinutes = 0, condensed = true): string {
    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;
    const humanisedHours = this.humaniseValue(hours, condensed ? 'h' : 'hr', !condensed);
    const humanisedMinutes = this.humaniseValue(minutes, condensed ? 'm' : 'min', !condensed);
    return `${humanisedHours}${hours && minutes ? ' ' : ''}${humanisedMinutes}`;
  }
}
