import { createAction, props } from '@ngrx/store';
import { MerchantWithMultiStoresSession } from '../../utilities/merchants-with-multi-stores.interface';

const clearCart = createAction('[Merchant With Resturants Page] Clear cart');
const updateMultiStoreSession = createAction(
  '[Merchant With Resturants Page] Update multi store session',
  props<{ merchantDetails: MerchantWithMultiStoresSession }>()
);

export const merchantWithMultiStoresPageActions = {
  clearCart,
  updateMultiStoreSession,
};
