import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TrackByFunction } from '@angular/core';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { StoreCategoryStatusId, StoreCategory } from '@libs/shared/types';

@Component({
  selector: 'kody-menu-categories',
  templateUrl: './menu-categories.component.html',
  styleUrls: ['./menu-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuCategoriesComponent {
  @Input() categories: StoreCategory[];
  @Input() isMerchantView: boolean;
  @Input() primaryColor? = 'primary';
  @Input() secondaryColor? = 'secondary';
  @Input() selectedCategory: StoreCategory;

  @Output() longPressCategory = new EventEmitter<StoreCategory>();
  @Output() selectCategory = new EventEmitter<StoreCategory>();

  readonly StoreCategoryStatusId = StoreCategoryStatusId;
  readonly faCheck = faCheck;
  readonly faTimes = faTimes;

  trackBy: TrackByFunction<StoreCategory> = (index, { restaurantMenuCategoryId }) => restaurantMenuCategoryId;
}
