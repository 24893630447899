import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Inject, Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { featureFlagsActions } from '../actions/feature-flags.actions';
import { from, of } from 'rxjs';
import { LAUNCH_DARKLY_CLIENT_ID } from '../../tokens/launch-darkly-client-id.token';
import { FeatureFlagFacadeService } from '../..//services/feature-flag-facade/feature-flag-facade.service';

@Injectable()
export class FeatureFlagsEffects {
  constructor(
    private actions$: Actions,
    private featureFlagService: FeatureFlagService,
    private featureFlagFacadeService: FeatureFlagFacadeService,
    @Inject(LAUNCH_DARKLY_CLIENT_ID) private launchDarklyClientId: string
  ) {}

  initialize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureFlagsActions.initialize),
      switchMap(({ context }) => {
        return from(this.featureFlagService.initialize(this.launchDarklyClientId, context));
      }),
      map(() => featureFlagsActions.startWatching({ initialFlags: this.featureFlagService.flags() }))
    )
  );

  watch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureFlagsActions.startWatching),
      switchMap(() => this.featureFlagService.flagChanges$()),
      map((flags) => featureFlagsActions.flagsChanged({ flags }))
    )
  );

  close$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureFlagsActions.close),
      concatLatestFrom(() => this.featureFlagFacadeService.initialized()),
      switchMap(([, initialized]) => (initialized ? from(this.featureFlagService.close()) : of(null))),
      map(() => featureFlagsActions.closed())
    )
  );
}
