import { kodyOrderBaseRoutes } from './kodyorder-base-routes.constant';

const { root, multiStores, table, clickCollect, counter, payment, orderConfirmed, orderFailed } = kodyOrderBaseRoutes;
export const kodyOrderRoutes = {
  table: (merchantStoreId: string) => `${root}/${merchantStoreId}/${table}`,
  multiStores: (merchantStoreId: string) => `${root}/${merchantStoreId}/${multiStores}`,
  clickCollect: (merchantStoreId: string) => `${root}/${merchantStoreId}/${clickCollect}`,
  counter: (merchantStoreId: string) => `${root}/${merchantStoreId}/${counter}`,
  payment: (merchantStoreId: string) => `${root}/${merchantStoreId}/${payment}`,
  orderConfirmed: (merchantStoreId: string, orderId: string) => `${root}/${merchantStoreId}/${orderConfirmed}/${orderId}`,
  orderFailed: (merchantStoreId: string, orderId: string) => `${root}/${merchantStoreId}/${orderFailed}/${orderId}`,
};
