import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { consumerMenuFeatureStateKey } from './constants/consumer-menu-feature-state-key.constant';
import { consumerMenuReducers } from './constants/consumer-menu-reducers.constant';
import { ConsumerMenuAddonEffects } from './effects/consumer-menu-addons.effects';
import { ConsumerMenuPageEffects } from './effects/consumer-menu-page.effects';
import { MerchantWithMultiStoresPageEffects } from './effects/merchant-with-multi-stores-page.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(consumerMenuFeatureStateKey, consumerMenuReducers),
    EffectsModule.forFeature([ConsumerMenuAddonEffects, ConsumerMenuPageEffects, MerchantWithMultiStoresPageEffects]),
  ],
})
export class FeatureMenuStateModule {}
