import { Component, OnDestroy } from '@angular/core';
import { FeatureFlagFacadeService, featureFlagsActions } from '@libs/utility-feature-flags';

@Component({
  selector: 'kodypay-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  constructor(private featureFlagFacadeService: FeatureFlagFacadeService) {}

  ngOnDestroy(): void {
    this.featureFlagFacadeService.dispatch(featureFlagsActions.close());
  }
}
