import { createAnimation } from '@ionic/core';

export const routerAnimation = (_, opts) => {
  const { direction, enteringEl, leavingEl } = opts;
  if (direction === 'back') {
    const animation = createAnimation().duration(200).easing('ease-in-out');
    const enteringAnimation = createAnimation().addElement(enteringEl).beforeRemoveClass(['ion-page-invisible']);
    const leavingAnimation = createAnimation().addElement(leavingEl).beforeRemoveClass(['ion-page-invisible']);
    enteringAnimation.fromTo('transform', 'translateX(-100%)', 'translateX(0%)');
    leavingAnimation.fromTo('transform', 'translateX(0%)', 'translateX(100%)');
    return animation.addAnimation([enteringAnimation, leavingAnimation]);
  } else {
    const animation = createAnimation().easing('ease-in-out');
    const enteringAnimation = createAnimation().addElement(enteringEl).beforeRemoveClass(['ion-page-invisible']);
    const leavingAnimation = createAnimation().addElement(leavingEl).beforeRemoveClass(['ion-page-invisible']);
    enteringAnimation.fromTo('transform', 'translateX(100%)', 'translateX(0%)').duration(200);
    leavingAnimation.fromTo('transform', 'translateX(0%)', 'translateX(-100%)').duration(200);
    return animation.addAnimation([leavingAnimation, enteringAnimation]);
  }
};
