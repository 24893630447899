import { StoreItem } from '@libs/shared/types';
import { createAction, props } from '@ngrx/store';

const enterPage = createAction('[Consumer Menu Page] Enter page', props<{ merchantStoreId: string }>());

const leavePage = createAction('[Consumer Menu Page] Leave page');

const addItem = createAction('[Consumer Menu Addons] Add item', props<{ item: StoreItem }>());

const filterByMenuCategory = createAction('[Consumer Menu Page] Filter by category', props<{ menuCategoryId: string }>());

const searchMenuItems = createAction('[Consumer Menu Page] Search menu items', props<{ searchTerm: string; menuCategoryId: string }>());

export const consumerMenuPageActions = {
  enterPage,
  leavePage,
  addItem,
  filterByMenuCategory,
  searchMenuItems,
};
