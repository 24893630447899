import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppStateModule } from '@libs/app-kody-order/utility-app-state';
import { FeatureMenuStateModule } from '@libs/feature-menu';
import { APP_ENVIRONMENT, LoggingInterceptor } from '@libs/shared/utilities';
import { BRANDING_INITIALIZER } from '@libs/utility-branding';
import { FeatureFlagsStateModule, FEATURE_FLAG_INITIALIZER, LAUNCH_DARKLY_CLIENT_ID } from '@libs/utility-feature-flags';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { routerAnimation } from './app-modal-animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
      navAnimation: routerAnimation,
    }),
    AppRoutingModule,
    AppStateModule,
    FeatureFlagsStateModule,
    FeatureMenuStateModule,
    HttpClientModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: APP_ENVIRONMENT, useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
    { provide: LAUNCH_DARKLY_CLIENT_ID, useValue: environment.launchDarklyClientId },

    FEATURE_FLAG_INITIALIZER,
    BRANDING_INITIALIZER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
