<kody-shared-ui-modal [title]="item.name" class="add-on-modal" [subheader]="true">
  <div class="add-on-modal__sub-header" kody-modal-sub-header>
    <kody-chip color="primary" *ngIf="item.tags">{{ item.tags }}</kody-chip>
    <span class="add-on-modal__item-price">{{ item.price | currency : '£' }}</span>
  </div>

  <div class="add-on-modal__content" kody-modal-content>
    <!-- Addons not associated with a group -->
    <ng-container *ngIf="itemAddons.addons?.length">
      <h3 class="add-on-modal__title">Choose</h3>
      <kody-list-item-action
        (click)="$event.preventDefault()"
        *ngFor="let addon of itemAddons.addons; last as isLast; trackBy: addonTrackByFn"
        [title]="addon.name"
        [isLast]="isLast"
      >
        <div kody-list-header>
          <kody-chip color="primary" *ngIf="addon.tags" size="sm">{{ addon.tags }}</kody-chip>
          <h3>{{ addon.price | currency : '£' }}</h3>
        </div>
        <kody-toggle-button
          *ngIf="
            !addons[getUniqueAddonId(addon.merchantItemId)] ||
            (addon.selectionLimitsEnforced && addon.maxSelectionLimit < 1) ||
            (addon.selectionLimitsEnforced &&
              addons[getUniqueAddonId(addon.merchantItemId)]?.quantity === 1 &&
              addon.maxSelectionLimit === 1)
          "
          [toggled]="!!addons[getUniqueAddonId(addon.merchantItemId)]"
          (toggle)="toggle(addons, addon)"
        ></kody-toggle-button>

        <kody-stepper-ui
          *ngIf="
            (addon.selectionLimitsEnforced && addon.maxSelectionLimit > 1 && addons[getUniqueAddonId(addon.merchantItemId)]) ||
            (!addon.selectionLimitsEnforced && addons[getUniqueAddonId(addon.merchantItemId)]) ||
            (addon.selectionLimitsEnforced && addons[getUniqueAddonId(addon.merchantItemId)]?.quantity > 1)
          "
          [increment]="1"
          [min]="0"
          [max]="addon.maxSelectionLimit ? addon.maxSelectionLimit : undefined"
          [isMax]="addon.maxSelectionLimit === this.addons[getUniqueAddonId(addon.merchantItemId)]?.quantity"
          [value]="addons[getUniqueAddonId(addon.merchantItemId)].quantity"
          [decrementIcon]="icon.faMinus"
          [incrementIcon]="icon.faPlus"
          (step)="step($event, addon, addons)"
        ></kody-stepper-ui>
      </kody-list-item-action>
    </ng-container>

    <!-- Group addons -->
    <ng-container *ngIf="itemAddons.addonGroups?.length">
      <ng-container *ngFor="let addonGroup of itemAddons.addonGroups; trackBy: addonGroupTrackByFn">
        <!-- Group addon title -->
        <h3 class="add-on-modal__title--group">
          <span>
            {{ addonGroup.addonGroupName }}
            <kody-chip
              *ngIf="addonGroup.maxSelectionLimit"
              [color]="totalAddonsInGroup[addonGroup.addonGroupId]?.quantity === addonGroup.maxSelectionLimit ? 'success' : 'grey'"
            >
              <span *ngIf="totalAddonsInGroup[addonGroup.addonGroupId]?.quantity">{{
                totalAddonsInGroup[addonGroup.addonGroupId].quantity
              }}</span>
              <span *ngIf="totalAddonsInGroup && !totalAddonsInGroup[addonGroup.addonGroupId]?.quantity">0</span>
              /{{ addonGroup.maxSelectionLimit }}</kody-chip
            >
          </span>
          <span class="add-on-modal__title--optional" *ngIf="!addonGroup.minSelectionLimit">(Optional)</span>
        </h3>

        <!-- Group addons items -->
        <kody-list-item-action
          class="add-on-modal__add-on-group-list"
          *ngFor="let addon of addonGroup.addons; last as isLast; trackBy: addonGroupTrackByFn"
          [title]="addon.name"
          [isLast]="isLast"
        >
          <div kody-list-header>
            <kody-chip color="primary" *ngIf="addon.tags" size="sm">{{ addon.tags }}</kody-chip>
            <h3>{{ addon.price | currency : '£' }}</h3>
          </div>
          <!-- Addon Groups with no selection limit -->
          <ng-container *ngIf="!addonGroup.selectionLimitsEnforced">
            <kody-toggle-button
              *ngIf="
                !addons[getUniqueAddonId(addon.merchantItemId, addonGroup)] ||
                (addon.selectionLimitsEnforced &&
                  addons[getUniqueAddonId(addon.merchantItemId, addonGroup)]?.quantity === 1 &&
                  addon.maxSelectionLimit === 1)
              "
              [toggled]="!!addons[getUniqueAddonId(addon.merchantItemId, addonGroup)]"
              (toggle)="toggle(addons, addon, addonGroup)"
            ></kody-toggle-button>

            <kody-stepper-ui
              *ngIf="
                (!addon.selectionLimitsEnforced && addons[getUniqueAddonId(addon.merchantItemId, addonGroup)]) ||
                (addon.selectionLimitsEnforced &&
                  addons[getUniqueAddonId(addon.merchantItemId, addonGroup)] &&
                  addon.maxSelectionLimit > 1) ||
                (addon.selectionLimitsEnforced && addons[getUniqueAddonId(addon.merchantItemId, addonGroup)]?.quantity > 1)
              "
              [increment]="1"
              [min]="0"
              [max]="addonGroup.maxSelectionLimit || undefined"
              [isMax]="addons[getUniqueAddonId(addon.merchantItemId, addonGroup)]?.quantity === addon.maxSelectionLimit"
              [value]="addons[getUniqueAddonId(addon.merchantItemId, addonGroup)].quantity"
              [decrementIcon]="icon.faMinus"
              [incrementIcon]="icon.faPlus"
              (step)="step($event, addon, addons, addonGroup)"
            ></kody-stepper-ui>
          </ng-container>

          <!-- Addon Groups with a selection limit of 0 to 1 -->
          <ng-container *ngIf="addonGroup.maxSelectionLimit === 1">
            <kody-toggle-button
              *ngIf="
                totalAddonsInGroup[addonGroup.addonGroupId]?.quantity !== addonGroup.maxSelectionLimit ||
                (totalAddonsInGroup[addonGroup.addonGroupId]?.quantity === 1 && addons[getUniqueAddonId(addon.merchantItemId, addonGroup)])
              "
              [toggled]="!!addons[getUniqueAddonId(addon.merchantItemId, addonGroup)]"
              (toggle)="toggle(addons, addon, addonGroup)"
            ></kody-toggle-button>

            <kody-exchange-button
              *ngIf="
                totalAddonsInGroup[addonGroup.addonGroupId]?.quantity === addonGroup.maxSelectionLimit &&
                !addons[getUniqueAddonId(addon.merchantItemId, addonGroup)]
              "
              (exchange)="exchange(addons, addon, addonGroup)"
            ></kody-exchange-button>
          </ng-container>

          <!-- Addon Groups with a selection limit of 0 to >1 or with min selection limits -->
          <ng-container *ngIf="addonGroup.maxSelectionLimit >= 2 || (addonGroup.minSelectionLimit && addonGroup.maxSelectionLimit !== 1)" )>
            <kody-toggle-button
              *ngIf="
                !addons[getUniqueAddonId(addon.merchantItemId, addonGroup)] ||
                (addon.selectionLimitsEnforced &&
                  addons[getUniqueAddonId(addon.merchantItemId, addonGroup)]?.quantity === 1 &&
                  addon.maxSelectionLimit === 1)
              "
              [toggled]="!!addons[getUniqueAddonId(addon.merchantItemId, addonGroup)]"
              (toggle)="toggle(addons, addon, addonGroup)"
              [disabled]="
                addonGroup.maxSelectionLimit
                  ? totalAddonsInGroup[addonGroup.addonGroupId]?.quantity >= addonGroup.maxSelectionLimit &&
                    !addons[getUniqueAddonId(addon.merchantItemId, addonGroup)]
                  : false
              "
            ></kody-toggle-button>

            <kody-stepper-ui
              *ngIf="
                (!addon.selectionLimitsEnforced && addons[getUniqueAddonId(addon.merchantItemId, addonGroup)]) ||
                (addon.selectionLimitsEnforced &&
                  addons[getUniqueAddonId(addon.merchantItemId, addonGroup)] &&
                  addon.maxSelectionLimit > 1) ||
                (addon.selectionLimitsEnforced && addons[getUniqueAddonId(addon.merchantItemId, addonGroup)]?.quantity > 1)
              "
              [increment]="1"
              [min]="0"
              [max]="addonGroup.maxSelectionLimit || undefined"
              [isMax]="
                addonGroup.maxSelectionLimit
                  ? (addon.selectionLimitsEnforced &&
                      addons[getUniqueAddonId(addon.merchantItemId, addonGroup)].quantity >= addon.maxSelectionLimit) ||
                    totalAddonsInGroup[addonGroup.addonGroupId]?.quantity >= addonGroup.maxSelectionLimit
                  : addons[getUniqueAddonId(addon.merchantItemId, addonGroup)]?.quantity === addon.maxSelectionLimit
              "
              [value]="addons[getUniqueAddonId(addon.merchantItemId, addonGroup)].quantity"
              [decrementIcon]="icon.faMinus"
              [incrementIcon]="icon.faPlus"
              (step)="step($event, addon, addons, addonGroup)"
            ></kody-stepper-ui>
          </ng-container>
        </kody-list-item-action>
      </ng-container>
    </ng-container>
  </div>

  <div kody-modal-footer>
    <ion-button expand="block" (click)="addToBasket(addons, item, totalAddonsInGroup, addonGroupsWithMinSelectionLimit)"
      >Add to basket</ion-button
    >
  </div>
</kody-shared-ui-modal>
