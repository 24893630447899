import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiStoresListComponent } from './multi-store-list.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [CommonModule, IonicModule.forRoot()],
  declarations: [MultiStoresListComponent],
  exports: [MultiStoresListComponent],
})
export class MultiStoresListComponentModule {}
