import { IonContent } from '@ionic/angular';
import { Directive, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, Subscription } from 'rxjs';
import { BreakpointService } from '@libs/utility-breakpoint';

@UntilDestroy()
@Directive({
  selector: '[kodyShrinkingHeader]',
})
export class ShrinkingHeaderDirective implements OnInit {
  @Input('kodyShrinkingHeader') container: HTMLElement | IonContent;
  @Input() defaultSize: number;
  @Input() shrunkSize: number;
  @Input() visibilityThreshold = 20; // Threshold in px before elements are made visible or hidden

  @Input() headerId: string; // Element id of the header, to apply shrinking to
  @Input() hideOnShrink: string; // Elements with this class will be hidden when the header is shrunk
  @Input() showOnShrink: string; // Elements with this class will be visible when the header is shrunk

  private scrollContainer: HTMLElement;
  private scrollSubscription: Subscription;
  private headerElement: HTMLElement;
  private hideOnShrinkElements: HTMLCollectionOf<Element>;
  private showOnShrinkElements: HTMLCollectionOf<Element>;

  constructor(private breakpointService: BreakpointService, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {}

  async ngOnInit(): Promise<void> {
    this.scrollContainer = this.container instanceof IonContent ? await this.container.getScrollElement() : this.container;
    if (!this.scrollContainer) return;

    let isLargeBreakpoint = this.breakpointService.isLg();
    if (!isLargeBreakpoint) {
      this.initialiseHeader();
    }

    this.breakpointService.onLg$.pipe(untilDestroyed(this)).subscribe((isNowLarge) => {
      if (!isLargeBreakpoint && isNowLarge) {
        // resetting the header when moving into the large breakpoint
        this.resetHeader();
      }
      if (isLargeBreakpoint && !isNowLarge) {
        // reinitialising the header when moving out of the large breakpoint
        this.initialiseHeader();
      }
      isLargeBreakpoint = isNowLarge;
    });
  }

  private initialiseHeader(): void {
    this.headerElement = this.document.getElementById(this.headerId);
    this.hideOnShrinkElements = this.hideOnShrink ? this.document.getElementsByClassName(this.hideOnShrink) : null;
    this.showOnShrinkElements = this.showOnShrink ? this.document.getElementsByClassName(this.showOnShrink) : null;
    this.scrollSubscription = fromEvent(this.scrollContainer, 'scroll')
      .pipe(untilDestroyed(this))
      .subscribe(({ target }) =>
        this.adjustElementOnScroll(
          (target as HTMLElement).scrollTop,
          this.headerElement,
          this.showOnShrinkElements,
          this.hideOnShrinkElements
        )
      );
  }

  private resetHeader(): void {
    this.adjustElementOnScroll(0, this.headerElement, this.showOnShrinkElements, this.hideOnShrinkElements);
    this.scrollSubscription.unsubscribe();
  }

  private adjustElementOnScroll(
    scrollTop: number,
    header: HTMLElement,
    showOnShrink: HTMLCollectionOf<Element> | null,
    hideOnShrink: HTMLCollectionOf<Element> | null
  ): void {
    // Shrink header based on scroll distance
    const shrinkingDistance = this.defaultSize - this.shrunkSize;
    if (scrollTop < shrinkingDistance) {
      header.style.height = `${this.defaultSize - scrollTop}px`;
    } else {
      header.style.height = `${this.shrunkSize}px`;
    }

    // Show / hide elements based on scroll distance
    const shrunk = scrollTop > this.visibilityThreshold;
    if (showOnShrink) this.setStyles(showOnShrink, 'opacity', Number(shrunk));
    if (hideOnShrink) this.setStyles(hideOnShrink, 'opacity', Number(!shrunk));
  }

  private setStyles(elements: HTMLCollectionOf<Element>, prop: string, val: unknown): void {
    Array.from(elements).forEach((el) => this.renderer.setStyle(el, prop, val));
  }
}
