import { ItemGroupAddons, StoreItem } from '@libs/shared/types';
import { isEmpty } from 'lodash';

export class ItemUtility {
  static getItemPrice(item: StoreItem, addons: ItemGroupAddons, quantity = 1): number {
    let price = 0;
    price += parseFloat(item.price);

    if (!isEmpty(addons)) {
      Object.entries(addons).forEach(([, { quantity, item }]) => {
        price += parseFloat(item.price) * quantity;
      });
    }

    return price * quantity;
  }
}
