<ng-container *ngFor="let category of categories; trackBy: trackBy; let $i = index">
  <ion-badge
    *ngIf="!isMerchantView"
    class="menu-categories__category"
    [color]="selectedCategory.restaurantMenuCategoryId === category.restaurantMenuCategoryId ? primaryColor : secondaryColor"
    (click)="selectCategory.emit(category)"
    [attr.kp-object]="'menu_category_' + $i"
    kodyRippleEffect
  >
    {{ category.name }}
  </ion-badge>

  <ion-badge
    *ngIf="isMerchantView"
    class="menu-categories__category"
    [color]="selectedCategory?.restaurantMenuCategoryId === category.restaurantMenuCategoryId ? primaryColor : secondaryColor"
    (click)="selectCategory.emit(category)"
    (kodyLongPress)="longPressCategory.emit(category)"
    [attr.kp-object]="'menu_category_' + $i"
    kodyRippleEffect
  >
    {{ category.name }}
    <kody-chip *ngIf="category.statusId === StoreCategoryStatusId.enabled" color="success"><fa-icon [icon]="faCheck"></fa-icon></kody-chip>
    <kody-chip *ngIf="category.statusId === StoreCategoryStatusId.disabled" color="danger"><fa-icon [icon]="faTimes"></fa-icon></kody-chip>
  </ion-badge>
</ng-container>
