import { Injectable } from '@angular/core';
import { Intercom } from '@capacitor-community/intercom';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  open(): void {
    Intercom.displayMessageComposer({ message: 'Hello there!' });
  }

  // TODO: move all intercom logic into this service
}
