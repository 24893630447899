import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastController: ToastController) {}

  async success(options: ToastOptions): Promise<void> {
    return await this.present({
      ...options,
      color: 'success',
    });
  }

  async danger(options: ToastOptions): Promise<void> {
    return await this.present({
      ...options,
      color: 'danger',
    });
  }

  dismiss(): void {
    this.toastController.dismiss();
  }

  async present({ duration = 2000, message, color, position = 'top', buttons }: ToastOptions): Promise<void> {
    const toast = await this.toastController.create({
      color,
      duration,
      message,
      position,
      buttons,
    });
    toast.present();
  }
}
