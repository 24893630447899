import { Inject, Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { appStateActions, AppStateFacadeService, CommonHttpErrorResponse } from '@libs/app-kody-order/utility-app-state';
import { Environment, OrderTypes, storeIdSessionStorageKey, UserSession } from '@libs/shared/types';
import { APP_ENVIRONMENT, SessionStorageService } from '@libs/shared/utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ConsumerMenuApiService } from '../../services/consumer-menu-api/consumer-menu-api.service';
import { consumerMenuAddonsActions } from '../actions/consumer-menu-addons.actions';
import { consumerMenuApiActions } from '../actions/consumer-menu-api.actions';
import { consumerMenuPageActions } from '../actions/consumer-menu-page.actions';

@Injectable()
export class ConsumerMenuPageEffects {
  constructor(
    @Inject(APP_ENVIRONMENT) private environment: Environment,
    private actions$: Actions,
    private consumerMenuApiService: ConsumerMenuApiService,
    private loadingController: LoadingController,
    private appStateFacadeService: AppStateFacadeService,
    private sessionStorageService: SessionStorageService
  ) {
    this.baseApiUrl = `${this.environment.baseApiUrl}/web/`;
  }
  private baseApiUrl: string;

  getClickAndCollectSlots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumerMenuPageActions.enterPage),
      switchMap(() => combineLatest([this.appStateFacadeService.getMerchantStore(), this.appStateFacadeService.getCart()])),
      filter(([merchantStore, cart]) => !!merchantStore && !!cart && cart.orderType === OrderTypes.clickCollect),
      take(1),
      map(() => appStateActions.getAvailableClickAndCollectSlots())
    )
  );

  getMenuCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumerMenuPageActions.enterPage),
      switchMap(() => this.appStateFacadeService.getMerchantStore()),
      filter((merchantStore) => !!merchantStore),
      switchMap((merchantStore) =>
        this.consumerMenuApiService.getMerchantStoreConsumerMenuCategories(this.baseApiUrl, merchantStore.merchantStoreId).pipe(
          map((menuCategories) => consumerMenuApiActions.getMenuCategoriesSuccess({ menuCategories })),
          catchError((error) => of(consumerMenuApiActions.getMenuCategoriesFailure({ error })))
        )
      )
    )
  );

  getMenuItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumerMenuApiActions.getMenuCategoriesSuccess),
      map(({ menuCategories }) =>
        consumerMenuPageActions.filterByMenuCategory({ menuCategoryId: menuCategories[0].restaurantMenuCategoryId })
      )
    )
  );

  filterMenuItemsByCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumerMenuPageActions.filterByMenuCategory),
      concatLatestFrom(() => this.appStateFacadeService.getMerchantStore()),
      switchMap(([{ menuCategoryId }, merchantStore]) =>
        this.consumerMenuApiService.getMerchantStoreConsumerMenuItems(this.baseApiUrl, merchantStore.merchantStoreId, menuCategoryId).pipe(
          map((menuItems) => {
            return consumerMenuApiActions.filterMenuCategoriesSuccess({ menuItems });
          }),
          catchError((error) => of(consumerMenuApiActions.getMenuItemsFailure({ error })))
        )
      )
    )
  );

  searchMenuItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumerMenuPageActions.searchMenuItems),
      concatLatestFrom(() => this.appStateFacadeService.getMerchantStore()),
      switchMap(([{ searchTerm, menuCategoryId }, { merchantStoreId }]) => {
        const command = searchTerm
          ? this.consumerMenuApiService.getMenuItemsByName(this.baseApiUrl, merchantStoreId, searchTerm)
          : this.consumerMenuApiService.getMerchantStoreConsumerMenuItems(this.baseApiUrl, merchantStoreId, menuCategoryId);
        return command.pipe(
          map((menuItems) => consumerMenuApiActions.getMenuItemsSuccess({ menuItems })),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );

  addItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumerMenuPageActions.addItem),
      mergeMap(async ({ item }) => {
        const loading = await this.loadingController.create({
          spinner: 'dots',
          cssClass: 'kp-loading',
          translucent: true,
          showBackdrop: false,
        });
        return { item, loading };
      }),
      switchMap(async ({ item, loading }) => {
        await loading.present();
        return consumerMenuAddonsActions.getAddons({ item });
      })
    )
  );

  removeStoreIdSessionStorageKey$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(consumerMenuPageActions.enterPage),
        switchMap(() => this.sessionStorageService.getItem<string>(storeIdSessionStorageKey)),
        tap((storageItem) => {
          if (storageItem) {
            this.sessionStorageService.removeItem(storeIdSessionStorageKey);
          }
        })
      ),
    { dispatch: false }
  );

  getCartFromSessionStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumerMenuPageActions.enterPage),
      switchMap(() => this.appStateFacadeService.getMerchantStore()),
      filter((merchantStore) => !!merchantStore),
      switchMap((merchantStore) =>
        from(this.sessionStorageService.getItem<UserSession>(merchantStore.merchantStoreId)).pipe(
          filter((userSession) => !!userSession),
          map(({ cart, table, clickCollectSlot }) => appStateActions.updateCartFromStorage({ cart, table, clickCollectSlot })),
          catchError((error) => of(appStateActions.skippingUpdatingCartFromStorage({ error })))
        )
      )
    )
  );
}
