import { Pipe, PipeTransform } from '@angular/core';
import { TimeService } from '@libs/shared/utilities';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  constructor(private timeService: TimeService) {}
  transform(minutes: number, condensed?: boolean): string {
    return this.timeService.getDurationMessageFromMinutes(minutes, condensed);
  }
}
