import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Gesture, GestureController, GestureDetail } from '@ionic/angular';
import { merge, Subject, Subscription, timer } from 'rxjs';
import { filter, takeUntil, switchMap } from 'rxjs/operators';

@Directive({ selector: '[kodyLongPress]' })
export class LongPressDirective implements AfterViewInit, OnDestroy, OnInit {
  @Input() durationThreshold = 500; // how long the user needs to press for, for the event to get triggered (ms)
  @Input() distanceThreshold = 0; // how far the user needs to move before the event gets cancelled (px)
  @Output('kodyLongPress') longPressed: EventEmitter<void> = new EventEmitter();
  constructor(private element: ElementRef, private gestureCtrl: GestureController) {}

  gesture: Gesture;
  subscription: Subscription;
  start$: Subject<void>;
  move$: Subject<GestureDetail>;
  end$: Subject<void>;

  ngOnInit(): void {
    this.start$ = new Subject();
    this.move$ = new Subject();
    this.end$ = new Subject();
  }

  ngAfterViewInit(): void {
    this.gesture = this.gestureCtrl.create(
      {
        el: this.element.nativeElement,
        threshold: 0,
        gestureName: 'long-press',
        onStart: () => this.start$.next(),
        onMove: (detail: GestureDetail) => this.move$.next(detail),
        onEnd: () => this.end$.next(),
      },
      true
    );
    this.gesture.enable(true);

    const cancel$ = merge(
      this.move$.pipe(filter(({ deltaX, deltaY }) => [deltaX, deltaY].some((d) => d > this.distanceThreshold))),
      this.end$
    );

    this.subscription = this.start$
      .pipe(switchMap(() => timer(this.durationThreshold).pipe(takeUntil(cancel$))))
      .subscribe(() => this.longPressed.emit());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
