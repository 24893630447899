import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'kody-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent {
  readonly faSearchIcon = faSearch;
  readonly faClearIcon = faTimes;
  @Input() searchControl = new FormControl();
  @Input() inputMode: 'none' | '' = '';
  @Input() placeholder? = 'Search';
  @Input() mode?: 'light' | 'dark' = 'dark';
}
