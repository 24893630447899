import { KodyOrderAppState, OrderTypes } from '@libs/shared/types';
import { ItemUtility } from '@libs/shared/utilities';
import { createSelector } from '@ngrx/store';

const appState = (state: KodyOrderAppState) => state;

const selectMerchantStore = createSelector(appState, (state: KodyOrderAppState) => {
  return state.appState.merchantStore;
});

const selectTable = createSelector(appState, (state: KodyOrderAppState) => {
  return state.appState.table;
});

const selectCart = createSelector(appState, (state: KodyOrderAppState) => {
  return state.appState.currentCart;
});

const selectStoreItemGroups = createSelector(appState, (state: KodyOrderAppState) => state.appState.currentCart?.itemGroups);

const selectItemGroupsTotalPrice = createSelector(appState, (state) => {
  if (!state.appState.currentCart?.itemGroups) return 0;
  const itemGroupPrices = state.appState.currentCart?.itemGroups.map(({ addons, item, quantity }) =>
    ItemUtility.getItemPrice(item, addons, quantity)
  );

  return itemGroupPrices.reduce((acc, p) => acc + p, 0);
});

const selectCartCount = createSelector(appState, (state: KodyOrderAppState) => {
  if (!state.appState.currentCart?.itemGroups) return 0;
  return state.appState.currentCart?.itemGroups.reduce((acc, { quantity }) => acc + quantity, 0);
});

const selectCurrentItemQuantity = createSelector(appState, (state: KodyOrderAppState) => {
  if (!state.appState.currentCart) {
    return;
  }
  return (state.appState.currentCart?.itemGroups || []).reduce((obj, { item, quantity }) => {
    if (obj[item.merchantItemId]) {
      obj[item.merchantItemId] = obj[item.merchantItemId] + quantity;
    } else {
      obj[item.merchantItemId] = quantity;
    }
    return obj;
  }, {} as { [key: string]: number });
});

const selectClickCollectSlot = createSelector(appState, (state: KodyOrderAppState) => {
  return state.appState.clickAndCollectSlot;
});

const selectAvailableClickCollectSlots = createSelector(appState, (state: KodyOrderAppState) => {
  return state.appState.availableClickAndCollectSlots;
});

const serviceChargeEnabled = createSelector(appState, (state: KodyOrderAppState) => {
  return (
    state.appState.merchantStore?.serviceCharge &&
    [OrderTypes.RESTAURANT_TABLE, OrderTypes.RESTAURANT_COUNTER].includes(state.appState.currentCart?.orderType)
  );
});

const serviceChargeValue = createSelector(appState, serviceChargeEnabled, selectItemGroupsTotalPrice, (state, enabled, itemPrice) => {
  if (!enabled) return 0;
  return state.appState.serviceChargeFixed ?? (state.appState.serviceChargePercent / 100) * itemPrice;
});

const serviceChargeOptions = createSelector(appState, selectItemGroupsTotalPrice, (state, itemPrice) => {
  const basePercent: number = state.appState.merchantStore?.serviceChargeAmount ?? 0;
  const options = [basePercent, basePercent + 2.5, basePercent + 5];
  return options.map((percent) => ({ percent, value: (percent / 100) * itemPrice }));
});

const serviceChargeFixed = createSelector(appState, (state) => state.appState.serviceChargeFixed);
const serviceChargePercent = createSelector(appState, (state) => state.appState.serviceChargePercent);
const serviceChargeUnlockThresholdPercent = createSelector(appState, (state) => {
  const basePercent = state.appState.merchantStore?.serviceChargeAmount ?? 0;
  const threshold = basePercent / 2; // Product decision to be half the base percent
  return threshold / 100;
});

const totalPrice = createSelector(
  selectItemGroupsTotalPrice,
  serviceChargeValue,
  (itemPrice, serviceChargeValue) => itemPrice + serviceChargeValue
);

export const appStateSelectors = {
  selectMerchantStore,
  selectTable,
  selectCart,
  selectStoreItemGroups,
  selectItemGroupsTotalPrice,
  selectCartCount,
  selectCurrentItemQuantity,
  selectClickCollectSlot,
  selectAvailableClickCollectSlots,
  totalPrice,

  serviceChargeOptions,
  serviceChargeEnabled,
  serviceChargeValue,
  serviceChargeFixed,
  serviceChargePercent,
  serviceChargeUnlockThresholdPercent,
};
