import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ChipComponentModule, LongPressModule, SearchComponentModule } from '@libs/shared/ui';
import { StoreMenuContainerComponent } from './store-menu.container';
import { MenuCategoriesComponentModule } from '../../components/menu-categories/menu-categories.module';
import { MenuItemComponentModule } from '../../components/menu-item/menu-item.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    MenuCategoriesComponentModule,
    ChipComponentModule,
    MenuItemComponentModule,
    SearchComponentModule,
    LongPressModule,
  ],
  declarations: [StoreMenuContainerComponent],
  exports: [StoreMenuContainerComponent],
})
export class StoreMenuContainerComponentModule {}
