import { Injectable } from '@angular/core';
import { ClickCollectSlot, StoreCategory, StoreItem } from '@libs/shared/types';
import { StoreFacade } from '@libs/shared/utilities';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { consumerMenuSelectors } from '../../selectors/consumer-menu.selectors';

@Injectable({
  providedIn: 'root',
})
export class ConsumerMenuFacadeService extends StoreFacade {
  constructor(protected store: Store) {
    super(store);
  }

  getMenuItems(): Observable<StoreItem[]> {
    return this.store.select(consumerMenuSelectors.selectMenuItems);
  }

  getMenuCategories(): Observable<StoreCategory[]> {
    return this.store.select(consumerMenuSelectors.selectMenuCategories);
  }

  getAvailableSlots(): Observable<ClickCollectSlot[]> {
    return this.store.select(consumerMenuSelectors.selectAvailableSlots);
  }
}
