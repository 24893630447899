<ion-header
  class="menu-header"
  id="menu-header"
  [ngStyle]="{ height: defaultSize + 'px', 'background-image': headerImage }"
  [ngClass]="{ native: isNative, 'no-header-image': !headerImage }"
>
  <ion-toolbar
    class="menu-header__persistent"
    [class.menu-header-multi-store]="showBackButton"
    [ngStyle]="{ height: menuHeaderSizes.shrunk + 'px' }"
  >
    <ion-buttons *ngIf="isNative" slot="start">
      <ion-back-button text="" icon="chevron-back-outline" defaultHref="/user-profile" kp-object="menu_header_back_btn"></ion-back-button>
    </ion-buttons>

    <ion-buttons *ngIf="!isNative && showBackButton">
      <ion-back-button
        [class.menu-header-back-btn]="showBackButton"
        text="Back to Tokia square"
        (click)="goBack.emit()"
        icon="chevron-back-outline"
        defaultHref=""
        kp-object="menu_header_back_btn"
      ></ion-back-button>
    </ion-buttons>

    <span class="menu-header__price menu-header--show-on-shrink" slot="end" kp-object="menu_header_shrunk_total_bill">{{
      cartPrice | currency : '£'
    }}</span>

    <div class="menu-header__store-name">
      <div [kodyFitText]="storeName" maxFontSize="28" minFontSize="12" kp-object="menu_header_store_name"></div>
    </div>
    <span>
      {{ subtitle }}

      <!--  Switch between eating in / takeout  -->
      <ng-container *ngIf="orderType === OrderTypes.RESTAURANT_COUNTER">
        <span> - </span>
        <span data-test-id="toggleBtn" (click)="toggleEatingIn.emit()" class="clickable" kp-object="menu_header_eating_in_btn"
          >{{ !isEatingIn ? 'Eating in' : 'Takeout' }}?</span
        >
      </ng-container>

      <!--  Choose C&C slot  -->
      <span
        *ngIf="orderType === OrderTypes.CLICK_COLLECT && clickCollectSlots?.length && !storeIsClosed"
        data-test-id="selectSlotBtn"
        class="clickable"
        (click)="selectSlot.emit()"
        kp-object="menu_header_click_collect_slot"
      >
        {{ selectedSlot ? 'Change' : 'Choose' }} slot
      </span>

      <kody-shared-ui-duration
        *ngIf="orderType !== OrderTypes.CLICK_COLLECT"
        prefix=" - "
        [minutes]="waitTimeMinutes"
        kp-object="menu_header_wait_time"
      ></kody-shared-ui-duration>
    </span>
  </ion-toolbar>

  <div class="menu-header__info menu-header--hide-on-shrink">
    <section *ngIf="table && orderType === OrderTypes.table">
      <div>Table no.</div>
      <div class="menu-header__info-content" kp-object="menu_header_expanded_table">{{ table }}</div>
    </section>
    <section *ngIf="cartPrice != undefined">
      <div>Total bill</div>
      <div class="menu-header__info-content" kp-object="menu_header_expanded_total_bill">{{ cartPrice | currency : '£' }}</div>
    </section>
  </div>
</ion-header>

<!--Invisible element to take up required space-->
<div [ngStyle]="{ height: defaultSize + 'px' }"></div>

<!-- 'Patches up' gaps left by curved header shape -->
<div class="menu-header__backdrop"></div>
