import { KodyOrderState, OrderTypes } from '@libs/shared/types';
import { createNewCart, updateItemGroup, updateItemGroupQuantity } from '@libs/shared/utilities';
import { createReducer, Action, on } from '@ngrx/store';
import { appStateApiActions } from '../actions/app-state-api.actions';
import { appStateActions } from '../actions/app-state.actions';

export const initialState: KodyOrderState = {
  currentCart: undefined,
  merchantStore: undefined,
  clickAndCollectSlot: undefined,
  availableClickAndCollectSlots: undefined,
};

const appStateReducerFn = createReducer(
  initialState,
  on(appStateApiActions.getMerchantStoreSuccess, (state, { merchantStore, orderType, table }) => ({
    ...state,
    merchantStore,
    currentCart: createNewCart(merchantStore, orderType, table),
    table,
    serviceChargePercent: merchantStore.serviceCharge ? merchantStore.serviceChargeAmount : undefined,
  })),
  on(appStateActions.addItemToCart, (state, { item, addons, merchantStore, table }) => {
    const cart = state.currentCart ? state.currentCart : createNewCart(merchantStore, OrderTypes.table, table);
    const itemGroups = updateItemGroup(cart.itemGroups, item, addons);

    return {
      ...state,
      currentCart: {
        ...cart,
        itemGroups,
      },
    };
  }),
  on(appStateActions.updateCartItemQuantity, (state, { item, addons, quantity }) => {
    const itemGroups = updateItemGroupQuantity(state.currentCart?.itemGroups, item, addons, quantity);
    return {
      ...state,
      currentCart: {
        ...state.currentCart,
        itemGroups,
      },
    };
  }),
  on(appStateActions.updateCartFromStorage, (state, { cart, table, clickCollectSlot }) => ({
    ...state,
    currentCart: cart,
    table: table,
    clickAndCollectSlot: clickCollectSlot,
  })),
  on(appStateActions.setEatingIn, (state, action) => ({
    ...state,
    currentCart: {
      ...state.currentCart,
      eatingIn: action.eatingIn,
    },
  })),
  on(appStateActions.setOrderNotes, (state, { notes }) => ({
    ...state,
    currentCart: {
      ...state.currentCart,
      notes,
    },
  })),
  on(appStateActions.setClickAndCollectSlot, (state, { slot }) => {
    return {
      ...state,
      clickAndCollectSlot: slot,
    };
  }),
  on(appStateActions.setDefaultClickAndCollectSlot, (state, { availableSlots }) => ({
    ...state,
    clickAndCollectSlot: availableSlots?.[0],
  })),
  on(appStateApiActions.getAvailableClickCollectSlotsSuccess, (state, { availableSlots }) => ({
    ...state,
    availableClickAndCollectSlots: availableSlots,
  })),
  on(appStateActions.setServiceCharge, (state, { serviceCharge }) => ({
    ...state,
    serviceChargeFixed: serviceCharge.fixed,
    serviceChargePercent: serviceCharge.percent,
  })),
  on(appStateActions.clearState, () => initialState ));


export function appStateReducer(state: KodyOrderState | undefined, action: Action): KodyOrderState {
  return appStateReducerFn(state, action);
}
