import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  search$(input: FormControl, unsubscribe: Subject<boolean>, defaultValue?: string): Observable<string> {
    return input.valueChanges.pipe(
      debounceTime(300),
      map((value: string) => value.trim()),
      distinctUntilChanged(),
      startWith(defaultValue || ''),
      takeUntil(unsubscribe)
    );
  }

  getFilteredList<T>(collection: T[], searchTerm: string, searchProp: string[]): T[] {
    return !searchTerm ? collection : this.filterItems(collection, searchTerm, searchProp);
  }

  private filterItems<T>(items: T[], searchTerm: string, keys: string[]): T[] {
    return items.filter((item) => keys.some((key) => String(item[key]).toLowerCase().includes(searchTerm.toLowerCase())));
  }
}
