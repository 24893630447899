import { Cart, ItemGroup, ItemGroupAddons, MerchantStore, OrderTypes, StoreItem } from '@libs/shared/types';
import { cloneDeep } from 'lodash';

export function updateItemGroup(currentGroups: ItemGroup[], item: StoreItem, addons: ItemGroupAddons): ItemGroup[] {
  let itemGroups = currentGroups || [];
  // Find a matching group (same item & exact addons)
  const matchedGroupIndex = itemGroups.findIndex((itemGroup) => itemGroupMatched(itemGroup, item, addons));
  // Increase the quantity if it exists
  if (matchedGroupIndex > -1) {
    itemGroups = itemGroups.map((existingGroup, i) =>
      i !== matchedGroupIndex ? existingGroup : { ...existingGroup, quantity: existingGroup.quantity + 1 }
    );
  } else {
    itemGroups = [...itemGroups, { item, addons, quantity: 1 }];
  }
  return itemGroups;
}

export function updateItemGroupQuantity(
  currentGroups: ItemGroup[],
  item: StoreItem,
  addons: ItemGroupAddons,
  quantity: number
): ItemGroup[] {
  let itemGroups = cloneDeep(currentGroups) || [];
  const matchedGroupIndex = itemGroups.findIndex((itemGroup) => itemGroupMatched(itemGroup, item, addons));
  if (!quantity) {
    // Remove item group
    itemGroups.splice(matchedGroupIndex, 1);
  } else {
    // Set item group quantity
    itemGroups = itemGroups.map((existingGroup, i) => (i !== matchedGroupIndex ? existingGroup : { ...existingGroup, quantity }));
  }
  return itemGroups;
}

export function createNewCart(merchantStore: MerchantStore, orderType: OrderTypes, table: string) {
  const cart: Cart = {};
  cart.eatingIn = false;
  cart.orderType = orderType;
  cart.restaurantTable = table;

  if (
    orderType == OrderTypes.RESTAURANT_COUNTER ||
    orderType == OrderTypes.CLICK_COLLECT ||
    orderType == OrderTypes.MANUAL_PAYMENT ||
    orderType == OrderTypes.SELF_CHECKOUT
  ) {
    cart.eatingIn = false;
  } else {
    cart.eatingIn = true;
  }

  cart.itemsMap = {};
  return cart;
}

function itemGroupMatched(itemGroup: ItemGroup, item: StoreItem, addons: ItemGroupAddons): boolean {
  // Item group is matched if it is the same item and all the addons have the same quantities
  if (itemGroup.item.merchantItemId !== item.merchantItemId) return false;
  if (Object.keys(itemGroup.addons).length !== Object.keys(addons).length) return false;
  const addonQuantitiesMatch = Object.entries(itemGroup.addons).every(([addonId, { quantity }]) => addons[addonId]?.quantity === quantity);
  return addonQuantitiesMatch;
}
