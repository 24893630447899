import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { merchantStoreDetails } from '../../utilities/merchants-with-multi-stores.interface';

@Component({
  selector: 'kody-multi-store-list',
  templateUrl: './multi-store-list.component.html',
  styleUrls: ['./multi-store-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiStoresListComponent {
  @Input() restaurants: merchantStoreDetails[];
  @Output() restaurantSelected = new EventEmitter<string>();

  baseImageUrl = '../../../../../../../assets/images/';
}
