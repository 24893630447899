import { ClickCollectSlot, Cart, ItemGroupAddons, MerchantStore, OrderTypes, StoreItem, ServiceChargeResult } from '@libs/shared/types';
import { createAction, props } from '@ngrx/store';

const enterApp = createAction('[App] Enter app', props<{ merchantStoreId: string; orderType?: OrderTypes; table?: string }>());
const resetApp = createAction('[App] Reset app', props<{ merchantStoreId: string; orderType?: OrderTypes; table?: string }>());
const setTable = createAction('[App] Set table number', props<{ table: string }>());
// We need to include the merchant store and table to create a new cart if needed with the necessary props so that if
// the user refreshed the browser we can save it all to session storage
const addItemToCart = createAction(
  '[App] Add item To Cart',
  props<{ item: StoreItem; addons: ItemGroupAddons; merchantStore: MerchantStore; table?: string }>()
);

const updateCartItemQuantity = createAction(
  '[App] Update cart item quantity',
  props<{ item: StoreItem; addons: ItemGroupAddons; quantity: number }>()
);

const updateCartFromStorage = createAction(
  '[App] Update cart from storage',
  props<{ cart: Cart; table?: string; clickCollectSlot?: ClickCollectSlot }>()
);

const skippingUpdatingCartFromStorage = createAction('[App] Skipping updating cart from session storage', props<{ error: Error }>());

const setEatingIn = createAction('[App] Set eating in', props<{ eatingIn: boolean }>());
const clearState = createAction('[App] Clear state');

const setOrderNotes = createAction('[App] Set order notes', props<{ notes: string }>());

const setDefaultClickAndCollectSlot = createAction(
  '[App] Set default click and collect slot',
  props<{ availableSlots: ClickCollectSlot[] }>()
);

const setClickAndCollectSlot = createAction('[App] Set click and collect slot', props<{ slot: ClickCollectSlot }>());

const getAvailableClickAndCollectSlots = createAction('[App] Get available click and collect slots');

const setServiceCharge = createAction('[App] Set service charge', props<{ serviceCharge: ServiceChargeResult }>());

const updateStoreBrandingTheme = createAction('[App] Update store branding theme', props<{ storeId: string }>());

export const appStateActions = {
  enterApp,
  resetApp,
  setTable,
  addItemToCart,
  updateCartItemQuantity,
  updateCartFromStorage,
  skippingUpdatingCartFromStorage,
  setOrderNotes,
  setClickAndCollectSlot,
  getAvailableClickAndCollectSlots,
  setDefaultClickAndCollectSlot,
  setEatingIn,
  setServiceCharge,
  clearState,
  updateStoreBrandingTheme,
};
