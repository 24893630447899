import { Inject, Injectable } from '@angular/core';
import { ModalController, ModalOptions } from '@ionic/angular';
import { forkJoin, from, Observable, of } from 'rxjs';
import { filter, pluck, switchMap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { ComponentRef } from '@ionic/core';

type ModalSize = 'xs' | 'sm' | 'md' | 'lg';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modalController: ModalController, @Inject(DOCUMENT) private document: Document) {}

  open<R, C extends ComponentRef & { prototype }>(
    component: C,
    componentProps: { [K in keyof C['prototype']]?: C['prototype'][K] }, // Type safe component props
    size: ModalSize,
    successDismissRole: string,
    override?: Partial<ModalOptions>
  ): Observable<R> {
    const presentingElement = this.getPresentingElement();

    return this.createModal(component, presentingElement, size, {
      ...override,
      componentProps,
    }).pipe(
      switchMap((modal) => forkJoin([of(modal), modal.present()])),
      switchMap(([modal]) => modal.onWillDismiss<R>()),
      filter((event) => event.role === successDismissRole),
      pluck('data')
    );
  }

  private createModal(
    component: ComponentRef,
    presentingElement: HTMLElement,
    size: ModalSize,
    override?: Partial<ModalOptions>
  ): Observable<HTMLIonModalElement> {
    return from(
      this.modalController.create({
        component,
        cssClass: `kp-modal-${size}`,
        swipeToClose: false,
        presentingElement,
        ...override,
      })
    );
  }

  private getPresentingElement(): HTMLElement {
    return this.document.querySelector('ion-router-outlet');
  }
}
