import { Injectable } from '@angular/core';
import { ApiError } from '@libs/shared/types';

const errors = {
  invalidWorkingHours: '1001',
  invalidTimeslot: '1003',
};

type ErrorType = keyof typeof errors;

@Injectable({
  providedIn: 'root',
})
export class ErrorResponseService {
  private getApiErrorCode(error: ApiError): string {
    return error?.error?.errorCode || '0';
  }

  hasError(error: ApiError, type: ErrorType): boolean {
    return this.getApiErrorCode(error) === errors[type];
  }
}
