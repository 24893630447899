import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { appStateActions, AppStateFacadeService } from '@libs/app-kody-order/utility-app-state';
import { kodyOrderBaseRoutes, kodyOrderRoutes, OrderTypes, storeIdSessionStorageKey } from '@libs/shared/types';
import { SessionStorageService } from '@libs/shared/utilities';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AppLegacyRedirectGuard implements CanActivate {
  constructor(
    private navController: NavController,
    private sessionStorageService: SessionStorageService,
    private appStateFacadeService: AppStateFacadeService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  canActivate = async (routeSnapshot: ActivatedRouteSnapshot): Promise<boolean> => {
    const url = new URL(this.document.defaultView.location.href);
    const urlSegments = url.pathname.split('/');

    if (!urlSegments.length) return true;
    // Handle legacy Order Confirmed Route
    if (urlSegments.length >= 3 && urlSegments[3] === 'order-confirm') {
      const orderId = urlSegments[4];

      // We have to store the storeId in session storage when the order is created as it is not retrievable from the redirect url
      const merchantStoreId = await this.sessionStorageService.getItem<string>(storeIdSessionStorageKey);
      if (merchantStoreId && orderId) {
        this.appStateFacadeService.dispatch(appStateActions.enterApp({ merchantStoreId }));
        this.navController.navigateForward(kodyOrderRoutes.orderConfirmed(merchantStoreId, orderId));
      }
      return;
    }

    // Handle all other routes (table, counter and click-collect)
    if (urlSegments.length >= 1) {
      const route = urlSegments[1];
      const params = url.searchParams;
      const merchantStoreId = params.get('storeId');

      if (!merchantStoreId) {
        // ? navigate to default route with message?
        return;
      }

      let table: string;
      let orderType: OrderTypes;

      switch (route) {
        case 'table':
          table = params.get('table');
          orderType = OrderTypes.table;
          break;
        case 'counter':
          orderType = OrderTypes.counter;
          break;
        case 'click-collect':
          orderType = OrderTypes.clickCollect;
          break;
        default:
          orderType = OrderTypes.counter;
          break;
      }
      const showTablePrompt = routeSnapshot.queryParams['promptTable'];
      if (showTablePrompt) {
        table = undefined; // we have to remove the table as the qr code may have a table number set
        while (!table) {
          // Keep prompting for a table number until they enter a non-blank string
          table = this.document.defaultView.prompt('Please enter your table number').trim();
        }
        this.appStateFacadeService.dispatch(
          appStateActions.enterApp({
            merchantStoreId,
            orderType: OrderTypes.table, // Force to table order, as the order type will be counter for these stores
            table,
          })
        );
      } else {
        this.appStateFacadeService.dispatch(appStateActions.enterApp({ merchantStoreId, orderType, table }));
      }
      this.navController.navigateRoot([kodyOrderBaseRoutes.root, merchantStoreId, route], {
        queryParams: { table },
      });
    }
  };
}
