import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DurationComponent } from './duration.component';
import { DurationPipe } from '../../pipes/duration.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [DurationComponent, DurationPipe],
  exports: [DurationComponent],
})
export class DurationComponentModule {}
