import { ClickCollectSlot, ItemAddonResponse, MerchantStore, StoreCategory, StoreItem } from '@libs/shared/types';
import { ConsumerMenuAddonsState } from './consumer-menu-addons.model';

export const consumerMenuStoreKey = 'consumerMenu';

export interface ConsumerMenuFeatureState {
  consumerMenuAddons: ConsumerMenuAddonsState;
  consumerMenuPage: ConsumerMenuPageState;
}

export interface ConsumerMenuState {
  consumerMenuPage?: ConsumerMenuPageState;
}

export interface ConsumerMenuAppState {
  [consumerMenuStoreKey]: ConsumerMenuState;
}

export interface ConsumerMenuPageState {
  merchantStore?: MerchantStore;
  merchantStoreCategories?: StoreCategory[];
  menuItems: StoreItem[];
  addons?: ItemAddonResponse;
  availableSlots?: ClickCollectSlot[];
}
