import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { menuHeaderSizes } from './menu-header-sizes.constant';
import { ClickCollectSlot, OrderTypes } from '@libs/shared/types';

@Component({
  selector: 'kody-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuHeaderComponent {
  @Input() storeName: string;
  @Input() subtitle: string;
  @Input() clickCollectSlots: ClickCollectSlot[];
  @Input() selectedSlot: ClickCollectSlot;
  @Input() isNative: boolean;
  @Input() showBackButton: boolean;
  @Input() isEatingIn: boolean;
  @Input() orderType: OrderTypes;
  @Input() cartPrice: string;
  @Input() storeIsClosed: boolean;
  @Input() table: string;
  @Input() headerImage?: string;
  @Input() waitTimeMinutes: number;
  @Input() defaultSize?: number = menuHeaderSizes.default;
  @Output() selectSlot = new EventEmitter<void>();
  @Output() toggleEatingIn = new EventEmitter<void>();
  @Output() goBack = new EventEmitter<void>();

  menuHeaderSizes = menuHeaderSizes;

  readonly OrderTypes = OrderTypes;
}
