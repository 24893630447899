import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { FeatureFlagFacadeService } from '../services/feature-flag-facade/feature-flag-facade.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard implements CanActivate {
  constructor(private featureFlagFacadeService: FeatureFlagFacadeService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    const featureFlag = route.data?.['featureFlag'];

    if (!featureFlag) return true;

    return this.featureFlagFacadeService.initialized().pipe(
      filter(Boolean),
      switchMap(() => this.featureFlagFacadeService.isActive(featureFlag))
    );
  }
}
