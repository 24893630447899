import { createFeatureSelector, createSelector } from '@ngrx/store';
import { consumerMenuFeatureStateKey } from '../constants/consumer-menu-feature-state-key.constant';
import { ConsumerMenuFeatureState } from '../models/consumer-menu-state.model';

const selectMenuState = createFeatureSelector<ConsumerMenuFeatureState>(consumerMenuFeatureStateKey);
const selectConsumerMenuPageState = createSelector(selectMenuState, (menuState: ConsumerMenuFeatureState) => menuState.consumerMenuPage);

const selectMerchantStore = createSelector(selectConsumerMenuPageState, (consumerMenuPage) => consumerMenuPage?.merchantStore);
const selectMenuItems = createSelector(selectConsumerMenuPageState, (consumerMenuPageState) => consumerMenuPageState?.menuItems);
const selectMenuCategories = createSelector(
  selectConsumerMenuPageState,
  (consumerMenuPageState) => consumerMenuPageState?.merchantStoreCategories
);
const selectAvailableSlots = createSelector(selectConsumerMenuPageState, (consumerMenuPage) => consumerMenuPage?.availableSlots);

export const consumerMenuSelectors = {
  selectMerchantStore,
  selectMenuCategories,
  selectMenuItems,
  selectAvailableSlots,
};
